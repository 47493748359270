import request from '@/utils/request'

export function reqUserInfo(data) {
  return request({
    url: '/web/user/sys/info',
    method: 'get',
    data
  })
}

export function sendMsg(data) {
  return request({
    url: '/laas/common/sendMsg',
    method: 'post',
    data
  })
}
export function reqGetArea(data) {
  return request({
    url: '/web/common/getArea',
    method: 'get',
    data
  })
}
export function reqGetDict(params) {
  return request({
    url: '/web/common/getDict',
    method: 'get',
    params
  })
}
